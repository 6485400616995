<template>
  <div class="admin-search">
    <div class="title">
      <span class="s">搜索词列表</span>
    </div>
    <div class="search-cell">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="关键字：">
          <el-input
            v-model="formInline.keyword"
            size="mini"
            placeholder="关键字"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            @click="
              page = 1;
              getList();
            "
            >查询</el-button
          >
          <!-- <el-button size="mini" type="success" @click="addBanner"
            >新增</el-button
          > -->
          <el-button size="mini" plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="keywords"
          label="搜索词"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="views"
          label="搜索数量"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="create_time"
          label="搜索时间"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="cz"
          label="操作"
        >
          <template slot-scope="scope">
            <!-- <span class="btn" @click="edit(scope.row)">编辑</span> -->
            <span class="btn" @click="handleDel(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        :page-size="page_size"
        layout="prev, pager, next"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { searchList, searchDel } from "@/api/admin.js";
export default {
  data() {
    return {
      formInline: {
        keyword: "",
      },
      page: 1,
      page_size: 10,
      tableData: {},
      schoolList: {},
      loading: true,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      searchList({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },

    handleReset() {
      this.formInline = {
        keyword: "",
      };
      this.page = 1;
      this.getList();
    },
    addBanner() {
      this.$router.options.routes.forEach((item) => {
        if (item.name == "admins") {
          item.children.forEach((val) => {
            if (val.name == "adminAddAdvert") {
  
              val.meta.bread[val.meta.bread.length - 1].name = "新增广告";
            }
          });
        }
      });
      sessionStorage.removeItem("ADMIN_ADVERT_INFO");
      this.$router.push("adminAddAdvert");
    },
    edit(val) {
      this.$router.options.routes.forEach((item) => {
        if (item.name == "admins") {
          item.children.forEach((val) => {
            if (val.name == "adminAddAdvert") {
              val.meta.bread[val.meta.bread.length - 1].name = "编辑广告";
            }
          });
        }
      });
      sessionStorage.ADMIN_ADVERT_INFO = JSON.stringify(val);
      this.$router.push("adminAddAdvert");
    },
    pageChange(index) {
      this.page = index;
      this.getList();
    },
    handleDel(val) {
      this.$confirm("确定删除该搜索词吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        searchDel({
          id: val.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("删除成功");
          }
          this.getList();
        });
      });
    },
  },
};
</script>
<style lang="less">
.admin-search {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-table--border .el-table__cell {
  border-color: #000;
}
</style>